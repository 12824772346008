import { SCAN_RESULT_READ_FAIL } from "constants/scan";

import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkIsGroupedItem,
  checkIsItemUsingManagementDate,
  getLabelCombinedWithSKUIdAndManagementDate,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";

import useScan from "hooks/common/useScan";
import {
  checkIsMultiLocationItem,
  getCounterKeyFromScanResultByInspectingIdInProgress,
  getSingleLocationCounterKeyFromScanResult,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";
import useUnverifiedItem from "pages/receiving/inspection/:id/hooks/useUnverifiedItem";

/**
 * 일반검수/분할검수를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      itemList: ReceivingItem[];
      groupedItemIdInProgress: number;
    }
  | { scanType: "multi"; itemId: number; inspectingId?: string };

export default function useScanInspectionSKU({
  skuCounting,
  receivingId,
  setRowInfoToHighlight,
  startInspectionAt,
  registeredUnverifiedList,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForInspection;
  receivingId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  startInspectionAt: string | undefined;
  registeredUnverifiedList?: ReceivingItem[];
}) {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const queryClient = useQueryClient();

  const {
    mutate: assignInspectorToItem,
    ResponseHandler: ResponseHandlerOfAssigningInspectorToItem,
  } = RECEIVING_QUERY.useAssignInspectorToItem();

  const {
    mutate: setInspectionStarted,
    ResponseHandler: ResponseHandlerOfSettingInspectionStarted,
  } = RECEIVING_QUERY.useSetInspectionStarted();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const {
    addScannedUnverifiedItemToInspection,
    ResponseHandlerOfUpdateUnverifiedItem,
  } = useUnverifiedItem({
    receivingId,
    registeredUnverifiedList,
    onSuccessForCreateUnverifiedItem: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({ id: receivingId })
      );
    },
  });

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!receivingId || !currentManager) return;

      // 허공이나 손상된 바코드를 스캔하는 경우
      if (scanResult === SCAN_RESULT_READ_FAIL) {
        setValidationError({
          title: (
            <>
              정상 스캔이 되지 않았습니다.
              <br />
              다시 스캔하거나 직접 입력해 주세요.
            </>
          ),
        });

        return;
      }

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.inspectingId) {
          setValidationError({
            title: `분할검수할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        // 분할검수의 경우 선택된 inspectingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByInspectingIdInProgress(
          {
            counterData: skuCounting,
            scanResult,
            inspectingIdInProgress: scanTypeInfo.inspectingId,
          }
        );
      }

      if (scanTypeInfo.scanType === "single") {
        const itemListWithoutUnverifiedItem = scanTypeInfo.itemList.filter(
          checkForNormalItemAsInspection
        );

        const usesManagementDate = checkIsItemUsingManagementDate(
          itemListWithoutUnverifiedItem,
          scanResult
        );

        const isMultiLocationItem = checkIsMultiLocationItem({
          counterData: skuCounting,
          scanResult,
        });
        /**
         * 관리일자를 사용하는 경우에는 분할검수된 아이템의 selection 컬럼이 disabled 처리 되어있기 때문에
         * 관리일자를 사용하지 않는 케이스만 확인함
         */
        if (!usesManagementDate && isMultiLocationItem) {
          setValidationError({
            title: `분할검수인 상태에서 스캔이 불가능합니다.`,
          });
          return;
        }

        const isGroupedItemSelected = !!scanTypeInfo.groupedItemIdInProgress;
        const isGroupedItem = checkIsGroupedItem(
          itemListWithoutUnverifiedItem,
          scanResult
        );

        if (isGroupedItem && !isGroupedItemSelected) {
          setValidationError({
            // 현재 '입고관리 > 검수'에서 grouped item은 관리일자가 있는 경우 밖에 없음
            title: "관리일자를 선택 후 스캔해주세요.",
          });
          return;
        }

        scannedCounterKey = getSingleLocationCounterKeyFromScanResult({
          counterData: skuCounting,
          scanResult,
          itemIdInprogress: scanTypeInfo.groupedItemIdInProgress,
        });

        const isScannedItemInList = !!itemListWithoutUnverifiedItem.some(
          (item) =>
            getFormattedSingleSkuId(item.sku.id) === scanResult ||
            item.sku.barCode === scanResult
        );
        // 관리일자가 지정된 아이템을 선택한 상태에서 리스트 내의 다른 아이템을 스캔한 경우
        if (
          Boolean(scanTypeInfo.groupedItemIdInProgress) &&
          isScannedItemInList &&
          !scannedCounterKey
        ) {
          const inProgressItem = itemListWithoutUnverifiedItem.find(
            (item) => item.id === scanTypeInfo.groupedItemIdInProgress
          );

          setValidationError({
            title: `현재 선택한 상품(${getLabelCombinedWithSKUIdAndManagementDate(
              {
                SKUId: inProgressItem?.sku.id,
                managementKind: inProgressItem?.sku.managementKind,
                managementDate: inProgressItem?.managementDate,
              }
            )})의 바코드를 스캔해주세요.`,
          });
          return;
        }
      }

      // 불일치의 경우 (등록된 SKU ID 나 바코드가 아닌 경우)
      if (!scannedCounterKey) {
        addScannedUnverifiedItemToInspection(scanResult);

        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      if (target.isCompleteInspecting) {
        setValidationError({
          title: `이미 검수완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const title = (() => {
          if (scanTypeInfo.scanType === "multi") {
            return `현재 작업중인 분할 검수를 완료한 후에 다른 분할 검수를 진행할 수 있습니다.`;
          }

          if (scanTypeInfo.scanType === "single") {
            return `현재 작업중인 검수(${getLabelCombinedWithSKUIdAndManagementDate(
              {
                SKUId: skuCounting.skuInProgress?.skuId,
                managementKind: skuCounting.skuInProgress?.managementKind,
                managementDate: skuCounting.skuInProgress?.managementDate,
              }
            )})를 완료한 후에 다른 검수를 진행할 수 있습니다.`;
          }

          return "";
        })();

        setValidationError({
          title,
        });
        return;
      }

      const isInitialScan = !target.inspectorId && !target.current;
      // TODO: 스캔시 담당자 권한 확인 해야함 - 창고의 네트워크 상황때문에 해당 단계 뺐음
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignInspectorToItem(
          {
            pathParams: {
              receivingId,
              itemId: target.itemId,
            },
            inspectingId: target.inspectingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.inspectingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.inspectorId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 검수하고 있습니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.inspectingId,
        });
      }
    },
    [
      receivingId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setValidationError,
      addScannedUnverifiedItemToInspection,
      assignInspectorToItem,
      setRowInfoToHighlight,
      queryClient,
    ]
  );

  const checkIsInspectionStarted = useCallback(
    (scanResult: string) => {
      if (!startInspectionAt) {
        setInspectionStarted(
          {
            pathParams: { receivingId },
          },
          {
            onSuccess: () => {
              handleScanResult(scanResult);
            },
          }
        );
        return;
      }

      handleScanResult(scanResult);
    },
    [startInspectionAt, handleScanResult, setInspectionStarted, receivingId]
  );

  useScan(checkIsInspectionStarted);

  const ResultHandlerOfScanSKU = useMemo(
    () => (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAssigningInspectorToItem}
        {ResponseHandlerOfSettingInspectionStarted}

        {ResponseHandlerOfUpdateUnverifiedItem}
      </>
    ),
    [
      ValidationErrorModal,
      ResponseHandlerOfAssigningInspectorToItem,
      ResponseHandlerOfSettingInspectionStarted,
      ResponseHandlerOfUpdateUnverifiedItem,
    ]
  );

  return {
    ResultHandlerOfScanSKU,
  };
}
