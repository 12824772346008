import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { GET_DETAIL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import {
  getManagementDateLabel,
  getTeamLabelForBofulWorker,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ChipWithClear from "@sellernote/_sds-v1/src/components/ChipWithClear";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useScanLocation from "hooks/receiving/useScanLocation";
import useScanWarehousingSKU from "hooks/receiving/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/receiving/useSKUCountingForWarehousing";

import ItemStatus from "components/ItemStatus";

import CloseWarehousing from "./CloseWarehousing";
import CompleteWarehousing from "./CompleteWarehousing";
import SKUList from "./SKUList";
import Styled from "./index.styles";

export type ScanModeForReceivingWarehousing = "skuId" | "location";

export default function ReceivingWarehousingDetailWarehousing({
  receivingId,
  receivingDetail,
  refetchReceivingDetail,
}: {
  receivingId: number;
  receivingDetail: GET_DETAIL_RES_DATA | undefined;
  refetchReceivingDetail: ReturnType<
    typeof RECEIVING_QUERY.useGetManagerReceivingDetail
  >["refetch"];
}) {
  const [scanMode, setScanMode] =
    useState<ScanModeForReceivingWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [groupedItemIdInProgress, setGroupedItemIdInProgress] = useState(0);

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const canCloseWarehousing = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.CAN_CLOSE_WAREHOUSING
  );
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuCounting = useWarehousingCounting(skuItemsFilteredByActualQty);

  const { setScanSKUActive, ResultHandlerOfScanSKU } = useScanWarehousingSKU({
    scanType: "single",
    skuCounting,
    receivingId,
    setRowInfoToHighlight,
    setSkuInProgress: skuCounting.setSkuInProgress,
    itemList: skuItemsFilteredByActualQty,
    groupedItemIdInProgress,
    setScanMode,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    scanType: "single",
    skuInprogress: skuCounting.skuInProgress,
    counterData: skuCounting.counter.counterInfo,
    itemList: skuItemsFilteredByActualQty,
    groupedItemIdInProgress,
  });

  const getWorkerById = useGetWorkerByIdFactory();

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkHasUnfinishedWarehousingOfMine = useCallback(
    (items?: ReceivingItem[]) => {
      if (!items) return;

      return items.some((item) => {
        if (!item.placeItems) return false;

        return item.placeItems.some(
          (pi) => pi.placerId === currentManager?.id && !pi.isCompletePlacing
        );
      });
    },
    [currentManager?.id]
  );

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedWarehousingOfMine = checkHasUnfinishedWarehousingOfMine(
      skuItemsFilteredByActualQty
    );

    if (hasUnfinishedWarehousingOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    checkHasUnfinishedWarehousingOfMine,
    setCanNotLeavePage,
    skuItemsFilteredByActualQty,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage: ReactNode;

    if (skuCounting.skuInProgress) {
      const managementDateLabel = getManagementDateLabel(
        skuCounting.skuInProgress.managementKind,
        skuCounting.skuInProgress.managementDate
      );

      confirmMessage = (
        <>
          현재 카운트 중인 SKU ID(
          {getFormattedSingleSkuId(skuCounting.skuInProgress.skuId)} / 상태:{" "}
          <ItemStatus
            statusLabel={skuCounting.skuInProgress.statusLabel}
            isUnverifiedSku={skuCounting.skuInProgress.isUnverifiedSku}
          />
          {managementDateLabel && ` / ${managementDateLabel}`}
          )가 초기화 됩니다
        </>
      );
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: async (showSuccessMessage) => {
        const { data } = await refetchReceivingDetail({ throwOnError: true });

        if (!data) {
          return;
        }

        setRowInfoToHighlight(undefined);
        showSuccessMessage();
        skuCounting.reset(
          data.data.receiving.items.filter((item) => (item.actualQty ?? 0) > 0)
        );
      },
    };
  }, [skuCounting, refetchReceivingDetail]);

  const handleScanSKUClick = useCallback(() => {
    setScanMode("skuId");
  }, []);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
    setGroupedItemIdInProgress(0);
  }, [setScannedLocation, skuCounting]);

  return (
    <Layout
      navigator={{
        title: `${getFormattedReceivingId(
          receivingDetail?.receiving
        )} / ${getTeamLabelForBofulWorker({
          id: receivingDetail?.receiving.team?.id,
          name: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}

        <div className="header">
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </div>

        <div className="main-manager">
          <div className="main-manager-label">메인담당자</div>

          <ChipWithClear
            label={
              getWorkerById(receivingDetail?.receiving.managerId)?.name ?? "-"
            }
            colorTheme="dark"
            className="custom-chip-with-clear"
          />
        </div>

        <SKUList
          setScanMode={setScanMode}
          receivingId={receivingId}
          detailItems={skuItemsFilteredByActualQty}
          selectedLocation={scannedLocation}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          groupedItemIdInProgress={groupedItemIdInProgress}
          setGroupedItemIdInProgress={setGroupedItemIdInProgress}
          skuCounting={skuCounting}
          resetCountByCounterKey={resetCountByCounterKey}
          setConfirmModal={setConfirmModal}
          resetAfterCompleteWarehousingByForce={resetAfterComplete}
        />

        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>

        <div className="footer">
          {canCloseWarehousing ? (
            <CloseWarehousing receivingId={receivingId} />
          ) : (
            <CompleteWarehousing
              locationType="single"
              receivingId={receivingId}
              selectedLocation={scannedLocation}
              counterInProgress={
                skuCounting.skuInProgress
                  ? skuCounting.counter.counterInfo[
                      skuCounting.skuInProgress.counterKey
                    ]
                  : undefined
              }
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResultHandlerOfScanLocation}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}
