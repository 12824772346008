import React, { useCallback, useMemo } from "react";
import produce from "immer";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_CANCELING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getGroupedObjectByProperty } from "@sellernote/_shared/src/utils/common/etc";
import {
  getItemIdListByWorkingLocation,
  getRestockingSKUId,
} from "@sellernote/_shared/src/utils/fulfillment/canceling";
import { getLocationBarcodeById } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useConfirmModal from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import ResetCount from "pages/canceling/detail/:id/RestockInvoice/ResetCount";

import CountForScanning from "components/CountForScanning";
import SKUInfo from "components/SKUInfo";

import useCancelingMessageModal from "./useCancelingMessageModal";
import { CompletedList, WorkingLocation } from "./useRestockCanceling";

export interface CancelingRestockingTableItem {
  locationName: React.ReactNode;
  SKUId: React.ReactNode;
  count: React.ReactNode;
  reset: React.ReactNode;
}

interface GroupedRestockingInfoByLocationId {
  [locationId: string]: SKUInfoForCancelingRestocking[];
}

const LocationToRestock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/**
 * 취소 재입고 리스트 관리
 */
export default function useRestockCancelingTable({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  setRowInfoToHighlight,
  completedList,
  isFirstSKUScan,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<SKUInfoForCancelingRestocking[]>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
}) {
  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );
  const restockingList = useRecoilValue(
    FULFILLMENT_CANCELING_SELECTORS.RESTOCKING_LIST
  );

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const { MessageModal, modalOpenHandler } = useCancelingMessageModal();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const itemIdListByWorkingLocation = useMemo(
    () =>
      getItemIdListByWorkingLocation({
        restockingList,
        workingLocationId: workingLocation?.id ?? 0,
      }),
    [restockingList, workingLocation?.id]
  );

  const handleCountReset = useCallback(
    ({ itemIdToReset }: { itemIdToReset: number }) =>
      () => {
        resetCount();
        deleteCompletedList();

        function resetCount() {
          setSKUInfoForScanning(
            produce((draft) => {
              const SKUInfoByItemIdToReset = draft?.find(
                (v) => v.id === itemIdToReset
              );

              if (SKUInfoByItemIdToReset) {
                SKUInfoByItemIdToReset.currentQty = 0;
              }
            })
          );
        }

        function deleteCompletedList() {
          const locationIdByItemIdToReset =
            SKUInfoForScanning.find((v) => v.id === itemIdToReset)
              ?.locationId ?? 0;

          completedList.current.locationIdList.delete(
            locationIdByItemIdToReset
          );
          completedList.current.itemIdList.delete(itemIdToReset);
          completedList.current.itemIdListByWorkingLocation.delete(
            itemIdToReset
          );
        }
      },
    [setSKUInfoForScanning, SKUInfoForScanning, completedList]
  );

  const handleCountAddByDirectInput = useCallback(
    ({ selectedItemId }: { selectedItemId: number }) =>
      (count: number | undefined) => {
        if (!workingLocation || !count) return;

        const selectedSKUId = getRestockingSKUId({
          itemId: selectedItemId,
          restockingList,
        });
        const selectedSKUBarcode = getFormattedSingleSkuId(selectedSKUId);

        setRowInfoToHighlight({ rowKey: selectedItemId });
        restoreCompletedItemIdListByWorkingLocation();
        countSKU();
        checkIsCompletedScanByScannedItemId();

        function restoreCompletedItemIdListByWorkingLocation() {
          // 다른 위치 작업을 하다가 기존에 완료된 위치의 상품을 초기화하해서 다시 돌아온 경우 작업 위치에서 완료된 상품 목록을 복원해준다.
          if (
            !isFirstSKUScan.current &&
            completedList.current.itemIdListByWorkingLocation.size === 0
          ) {
            itemIdListByWorkingLocation.forEach((itemId) => {
              if (completedList.current.itemIdList.has(itemId)) {
                completedList.current.itemIdListByWorkingLocation.add(itemId);
              }
            });
          }
        }

        function countSKU() {
          setSKUInfoForScanning(
            produce((draft) => {
              const selectedSKUInfo = draft.find(
                (v) => v.id === selectedItemId
              );

              if (selectedSKUInfo) {
                // 위에서 확인
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                selectedSKUInfo.currentQty = count!;
              }
            })
          );
        }

        function checkIsCompletedScanByScannedItemId() {
          const scannedSKUInfo = SKUInfoForScanning.find(
            (v) => v.id === selectedItemId
          );
          const isCompletedScanByScannedItemId =
            (scannedSKUInfo?.quantity ?? 0) === count;

          if (isCompletedScanByScannedItemId) {
            completedList.current.itemIdListByWorkingLocation.add(
              selectedItemId
            );
            completedList.current.itemIdList.add(selectedItemId);

            modalOpenHandler.openCompletedRestockingBySKUIdMessage(
              selectedSKUBarcode
            );

            checkIsCompletedScanByWorkingLocation();
          }

          function checkIsCompletedScanByWorkingLocation() {
            const isCompletedScanByWorkingLocation =
              JSON.stringify([...itemIdListByWorkingLocation].sort()) ===
              JSON.stringify(
                [...completedList.current.itemIdListByWorkingLocation].sort()
              );

            if (isCompletedScanByWorkingLocation) {
              completedList.current.locationIdList.add(
                workingLocation?.id ?? 0
              );

              completedList.current.itemIdListByWorkingLocation.clear();
              setWorkingLocation(undefined);
              handleScanButtonClick(undefined);
            }
          }
        }
      },
    [
      SKUInfoForScanning,
      completedList,
      handleScanButtonClick,
      isFirstSKUScan,
      itemIdListByWorkingLocation,
      modalOpenHandler,
      restockingList,
      setRowInfoToHighlight,
      setSKUInfoForScanning,
      setWorkingLocation,
      workingLocation,
    ]
  );

  const getRestockingTableRow = useCallback(
    ({
      groupedData,
      isSelectedScanSKUButton,
    }: {
      groupedData: SKUInfoForCancelingRestocking[];
      isSelectedScanSKUButton: boolean;
    }): TableDataListItem<CancelingRestockingTableItem>[] =>
      groupedData.map((v, i) => {
        const isFirstRow = i === 0;

        const isItemIdInWorkingLocation = itemIdListByWorkingLocation.some(
          (itemId) => itemId === v.id
        );
        const canOpenDirectInputModal =
          isItemIdInWorkingLocation && isSelectedScanSKUButton;

        return {
          noBorderBottom: i !== groupedData.length - 1,

          rowKey: v.id,

          locationName: isFirstRow ? (
            <LocationToRestock>
              <div>
                {getLocationBarcodeById({
                  locationList: locationListOfWarehouse,
                  locationId: v.locationId,
                })}
              </div>

              {v.managementKind ? (
                <div>
                  {INVENTORY_MANAGEMENT_KIND_MAP[v.managementKind]}{" "}
                  {toFormattedDate(v.managementDate, "YYYY-MM-DD")}
                </div>
              ) : (
                <div>-</div>
              )}
            </LocationToRestock>
          ) : (
            ""
          ),

          SKUId: (
            <SKUInfo
              skuId={v.skuId}
              skuBarcode={v.skuBarcode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />
          ),

          count: (
            <CountForScanning
              // type="restocking"
              canOpenDirectInputModal={canOpenDirectInputModal}
              // skuId={v.skuId}
              currentQty={v.currentQty}
              quantity={v.quantity}
              // onCountAdd={handleCountAddByDirectInput({ selectedItemId: v.id })}
              openDirectInputModal={() => {
                handleDirectInputModalOpen();
                setCountForScanning({
                  type: "restocking",
                  skuId: v.skuId,
                  currentQty: v.currentQty,
                  quantity: v.quantity,
                  onCountAdd: handleCountAddByDirectInput({
                    selectedItemId: v.id,
                  }),
                  managementKind: v.managementKind,
                  managementDate: v.managementDate,
                });
              }}
            />
          ),

          reset: (
            <ResetCount
              skuId={v.skuId}
              itemId={v.id}
              currentQtyByItemId={v.currentQty}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "content",
                  title: (
                    <>
                      {getFormattedSingleSkuId(v.skuId)}(SKU ID)
                      {v.managementKind && (
                        <>
                          <br />
                          {INVENTORY_MANAGEMENT_KIND_MAP[v.managementKind]}{" "}
                          {toFormattedDate(v.managementDate, "YYYY-MM-DD")}
                        </>
                      )}
                    </>
                  ),
                  body: "카운트를 초기화하겠습니까?",
                  actions: {
                    actionPositive: {
                      label: "예",
                      handleClick: () => {
                        handleCountReset({
                          itemIdToReset: v.id,
                        })();
                        setConfirmModal(undefined);
                      },
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
              // handleCountReset={handleCountReset}
            />
          ),
        };
      }),
    [
      fetchImageInfoBySKUId,
      handleCountAddByDirectInput,
      handleCountReset,
      handleDirectInputModalOpen,
      itemIdListByWorkingLocation,
      locationListOfWarehouse,
      setConfirmModal,
      setCountForScanning,
    ]
  );

  const getRestockingTableList = useCallback(
    (listData: SKUInfoForCancelingRestocking[]) => {
      const isSelectedScanSKUButton = selectedScanButtonType === "sku";

      const sortedListByLocationId = [...listData].sort(
        (a, b) => a.locationId - b.locationId
      );
      const groupedListByLocationId: GroupedRestockingInfoByLocationId =
        getGroupedObjectByProperty(sortedListByLocationId, "locationId");

      return Object.values(groupedListByLocationId).flatMap((groupedData) =>
        getRestockingTableRow({
          groupedData,
          isSelectedScanSKUButton,
        })
      );
    },
    [getRestockingTableRow, selectedScanButtonType]
  );

  const restockingTableList = useMemo(
    () => getRestockingTableList(SKUInfoForScanning),
    [SKUInfoForScanning, getRestockingTableList]
  );

  return {
    ConfirmModal,
    CountForScanningModal,

    restockingTableList,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    MessageModalOfDirectInput: MessageModal,
    ResponseHandlerOfGettingFileURLList,
  };
}
